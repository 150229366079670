<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">
							Internal Report
						</h2>
					</div>
				</div>
			</div>
		</div>

		<!-- Table Container Card -->
		<b-card no-body>
			<div class="m-3">
				<b-row>
					<!-- Date picker -->
					<b-col cols="12" md="9 p-0">
						<b-row>
							<b-col md="4 p-0 mr-1">
								<label for="example-input">From Date</label>
								<b-form-datepicker
									id="from-date"
									placeholder="Choose a date"
									local="en"
									v-model="from_date"
								/>
							</b-col>
							<b-col md="4 p-0">
								<label for="example-input">To Date</label>
								<b-form-datepicker
									id="to-date"
									placeholder="Choose a date"
									local="en"
									v-model="to_date"
								/>
							</b-col>
							<b-col md="2 d-flex align-items-end">
								<b-button @click="internalReport()" variant="success ">
									Filter
								</b-button>
							</b-col>
						</b-row>
					</b-col>
					<b-col cols="12" md="3 p-0">
						<b-form-group label="Hatch Id">
							<v-select
								v-model="selected_hatch_id"
								:options="hatch_id"
								label="hatch_id"
								:searchable="true"
								:clearable="true"
								@input="getParticularInternalReport()"
							/>
						</b-form-group>
					</b-col>
				</b-row>
			</div>
			<div v-if="getInternalReport && getInternalReport.length > 0">
				<b-table
					:items="getInternalReport"
					responsive
					:fields="fields"
					show-empty
					empty-text="No matching records found"
					class="position-relative"
				>
					<!-- <template #cell(index)="data">
						<div class="ml-1">
							{{ getInternalReport.from + data.index }}
						</div>
					</template> -->
					<template #cell(index)="data">
						<div class="ml-1">
							{{ data.index + 1 }}
						</div>
					</template>
					<!-- <template #cell(hatch_ability)="data">
            <div class="ml-1">
              {{ data.valuhatch_ability }}
            </div>
          </template> -->
					<!-- Column: Actions -->
					<template #cell(actions)="data">
						<div class="text-nowrap">
							<router-link
								class="text-secondary"
								:to="{
									name: 'apps-expense-edit',
									params: {
										expenseId: `${data.item.id}`,
										query: { hatch_id: `${data.item.hatch_id}` },
									},
								}"
							>
								<feather-icon icon="EditIcon" size="16"
							/></router-link>
						</div>
					</template>
				</b-table>
				<div class="mt-2 mb-1">
					<!-- <b-pagination
						:value="getInternalReport.current_page"
						:total-rows="getInternalReport.total"
						:per-page="getInternalReport.per_page"
						align="right"
						@change="internalReport"
					>
					</b-pagination> -->
				</div>
			</div>
			<div v-else>
				<b-row class="report-view">
					<b-col
						md="12"
						class="d-flex justify-content-center flex-column align-items-center"
					>
						<div class="circle">
							<b-img
								:src="
									require('@/assets/images/svg/reports/internal-report.svg')
								"
								style="width: 70px; height: 70px"
							/>
						</div>
						<h3 class="mt-5 ml-2 textInfo">No Internal Report</h3></b-col
					>
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BFormInput,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
	BTooltip,
	BTabs,
	BTab,
	BCardText,
	BFormDatepicker,
	BFormSelect,
	BImg,
	BFormGroup,
} from "bootstrap-vue";
import axiosIns from "@/libs/axios";
import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import vSelect from "vue-select";

export default {
	components: {
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		BTooltip,
		BCardText,
		BTabs,
		BTab,

		BFormDatepicker,
		BFormSelect,
		BImg,
		vSelect,
		BFormGroup,
	},
	data() {
		return {
			fields: [
				{ key: "index", label: "S.no" },
				"hatch_id",
				"flock_id",
				"total_eggs",
				"damaged_eggs",
				"total_vaccine_schedule",
				"no_chick",
				"total_infertile_egg",
				"cv",
				"fertility",
				"fertility",
				"ho_fertile",
				"hatch_ability",
			],
			hatch_id: [],
			from_date: null,
			selected_hatch_id: null,
			to_date: null,
			fromDate: null,
			toDate: null,
			hatch_id_list: [],
			getInternalReport: [],
			search_filter: {
				input_field: "",
			},
		};
	},
	created: function () {
		this.fromDate = moment().clone().startOf("month").format("YYYY-MM-DD");
		this.toDate = moment().clone().endOf("month").format("YYYY-MM-DD");
		this.internalReport();
	},

	methods: {
		moment() {
			return moment();
		},
		internalReport(pageNo = 1) {
			let url = "";
			const hatcheryId = this.$route.params.hatcheryId;

			if (this.from_date != null || this.to_date != null) {
				url = `web/hatcheries/${hatcheryId}/get-all-internal-reports?from_date=${this.from_date}&to_date=${this.to_date}`;
			} else {
				url = `web/hatcheries/${hatcheryId}/get-all-internal-reports?from_date=${this.fromDate}&to_date=${this.toDate}`;
			}
			let queryParams = {
				page: pageNo,
			};

			if (this.search_filter.input_field) {
				queryParams.input_field = this.search_filter.input_field;
			}
			if (this.search_filter.to_date) {
				queryParams.to_date = this.search_filter.to_date;
			}
			if (this.search_filter.from_date) {
				queryParams.from_date = this.search_filter.from_date;
			}

			axiosIns
				.get(this.addQueryString(url, queryParams))
				.then((response) => {
					this.getInternalReport = response.data.getInternalReport;
					this.hatch_id = response.data.allHatchId;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		getParticularInternalReport() {
			if (this.selected_hatch_id != null) {
				const hatchId = this.selected_hatch_id.hatch_id;
				const hatcheryId = this.$route.params.hatcheryId;
				let url = `web/hatcheries/${hatcheryId}/hatcher-hatch-id/${hatchId}/get-internal-report`;
				axiosIns
					.get(url)
					.then((response) => {
						this.getInternalReport = response.data.getInternalReport;
					})
					.catch((error) => {
						this.$refs.setErrors(error.response.data.error);
					});
			} else {
				this.internalReport();
			}
		},

		// excelDownload() {
		//   if (this.search_filter.from_date && this.search_filter.to_date) {
		//     this.search_filter.from_date = moment(
		//       this.search_filter.from_date
		//     ).format("YYYY-MM-DD");
		//     this.search_filter.to_date = moment(this.search_filter.to_date).format(
		//       "YYYY-MM-DD"
		//     );
		//   }
		//   let order_filter_data = this.prepareForm(this.search_filter);
		//   axiosIns
		//     .post(`web/export-expense-entry`, order_filter_data)
		//     .then((response) => {
		//       var tempLink = document.createElement("a");
		//       tempLink.style.display = "none";
		//       tempLink.href = response.data.file;
		//       tempLink.setAttribute("download", response.data.name);
		//       if (typeof tempLink.download === "undefined") {
		//         tempLink.setAttribute("target", "_blank");
		//       }
		//       document.body.appendChild(tempLink);
		//       tempLink.click();
		//       document.body.removeChild(tempLink);
		//       this.getSourceList();
		//     })
		//     .catch((error) => {
		//       error.response;
		//       const data = error.response.data.errors;
		//       data, "data";
		//       let arr = Object.values(data).flat().toString(",");
		//       arr, "array";
		//       this.$toast({
		//         component: ToastificationContent,
		//         position: "top-right",
		//         props: {
		//           icon: "CoffeeIcon",
		//           variant: "danger",
		//           text: arr,
		//         },
		//       });
		//       this.search_filter.to_date = null;
		//       this.search_filter.from_date = null;
		//       this.search_filter.input_field == null;
		//       this.getSourceList();
		//     });
		// },
	},
};
</script>

<style lang="scss" scoped>
.report-view {
	background-color: #f7f7f7;
	border-radius: 5px;
	padding: 2rem 2rem;
	margin: 3rem 3rem;
	height: 400px;
}
.circle {
	width: 195px;
	height: 195px;
	border-radius: 50%;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 2px solid #00c071;
	opacity: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}
.textInfo {
	color: #38c06c;
	font-size: 20px;
	font-weight: 600;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
